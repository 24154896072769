/* @ngInject */
export default class AppController {
  constructor(user, $injector) {
    this._$mdDialog = $injector.get("$mdDialog");
    this._$interval = $injector.get("$interval");
    this._$window = $injector.get("$window");
    this._$state = $injector.get("$state");

    this._Authentication = $injector.get("Authentication");
    this._SocketService = $injector.get("SocketService");
    this._SocketDisconnect = $injector.get("SocketDisconnectService");
    this._Auth = $injector.get("Auth");
    this._LoadingCircle = $injector.get("LoadingCircleService");
    this._Session = $injector.get("Session");
    this._InboxItemService = $injector.get("InboxItemService");

    // Resolved in App state- user is pulled from jwt payload
    this.user = user;
    this.features = $injector.get("Config").features;

    // This is for testing a socket disconnect
    const disconnectSocket = () => {
      this._SocketService.disconnect();
    };
    this._$window.disconnectSocket = disconnectSocket;

    this._SocketService.onConnect(() => {
      if (this._SocketService.connected) {
        const obj = {
          facilityId: user.facilityId,
          ownerFacilityIds: Object.keys(user.facility?.relationsToOwnerFacilities || {}),
          message: "connected",
        };
        this._SocketService.socket.emit("join", JSON.stringify(obj));
      }

      this.hideSocketDisconnectWarning();
    });

    const pagesWithoutNotification = ["Generated Report"];

    this._SocketService.onDisconnect(() => {
      if (!this._SocketService.connected) {
        if (this._Authentication.getJwt()) {
          if (!pagesWithoutNotification.includes(this._$state.current.name)) {
            this.displaySocketDisconnectWarning();
          }
        }
      }
    });

    this._SocketService.init();

    // Deselects/Unlocks the selected item when navigating away from inbox or refreshing a page
    // NOTE: This must be optimized to run as fast as possible to prevent pubsub issues (Avoid Asynchronous Functions)
    this._$window.addEventListener("beforeunload", () => {
      this._InboxItemService.disableLocking();
      const jwt = this._Authentication.getJwt();
      if (jwt) {
        const jwtPayload = this._Authentication.getJwtPayload(jwt);
        this._InboxItemService.unlockAllItemsForSession(jwtPayload.user, this._Session.sessionId);
      }
      this._Session.clear();
    });
  }

  displaySocketDisconnectWarning() {
    return this._SocketDisconnect
      .showNotification()
      .then((result) => {
        if (result === "ok" || result === true) {
          window.location.reload();
        }
      })
      .catch(console.error);
  }

  hideSocketDisconnectWarning() {
    return this._SocketDisconnect.hideNotification();
  }
}
