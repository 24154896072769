import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import InfoTooltip from "../../../shared/react/InfoTooltip.jsx";
import SwitchInput from "../../../shared/react/SwitchInput.jsx";

function MarkAsFinalizedToggle({
  // Props
  finalizedAt,
  disabled = false,
}) {
  return (
    <Box
      sx={{
        mb: 2,
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{display: "inline-flex"}}>
        <Typography variant="body2" align="right">
          Mark study as finalized
        </Typography>
        <InfoTooltip placement="top">
          Finalize a study if there is no further analysis to be performed
        </InfoTooltip>
      </Box>

      <Tooltip title={finalizedAt ? "Study has already been finalized" : null} placement="top">
        <span>
          <SwitchInput
            name="markAsFinalized"
            data-cy="mark-as-finalized-toggle"
            color="success"
            disabled={!!finalizedAt || disabled}
            otherProps={{edge: "end"}}
            defaultValue
          />
        </span>
      </Tooltip>
    </Box>
  );
}

MarkAsFinalizedToggle.propTypes = {
  finalizedAt: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(MarkAsFinalizedToggle);
